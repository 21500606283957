<template>
  <div class="page">
    <div class="list">
      <div
        class="item"
        v-for="(t, i) in listData"
        @click="toDetail(t, i)"
        :key="i"
      >
        <img src="./img/item-bg.png" alt="" class="bg" />
        <div class="item-top">
          <div class="banner">
            <img :src="t.image" alt="" />
          </div>
          <div class="name">{{ t.lotteryName }}</div>
        </div>
        <div class="item-bottom">
          <div class="starTime">
            <div class="point" style="background: #aed400"></div>
            <div class="time">抽奖开始时间：{{ t.startTime }}</div>
          </div>
          <div class="drawPrizeTime">
            <div class="point" style="background: #f7b500"></div>
            <div class="time">开奖时间：{{ t.endTime }}</div>
          </div>
          <div class="lastLine">
            <div class="lastLine-left">
              <img src="./img/men-num.png" alt="" />
              {{ t.applyPersons || 0 }}
            </div>
            <div :class="['lastLine-right', classFormat(t.status)]">
              {{ textFormat(t.status) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getLotteryListForMiniUrl } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
export default {
  data() {
    return {
      listData: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        userId: this.userId == 0 ? 0 : this.userId,
      };
      this.$axios
        .get(`${getLotteryListForMiniUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.listData = res.data;
            this.listData.forEach((item) => {
              if (item.image) {
                item.image = this.$handleImg(690, 330, item.image);
              }
            });
          }
        });
    },
    toDetail(t, i) {
      if (t.status == 1) {
        this.$toast("抽奖未开始");
      } else {
        this.$router.push({
          name: "luckyDrawDetail",
          query: {
            id: t.id,
          },
        });
      }
    },
    textFormat(status) {
      let x = null;
      switch (status) {
        case 2:
          x = "立即参与";
          break;
        case 3:
          x = "待开奖";
          break;
        case 4:
          x = "已结束";
          break;
        case 1:
          x = "未开始";
          break;
        case 99:
          x = "已下架";
          break;
        case 5:
          x = "谢谢参与";
          break;
        case 6:
          x = "中奖啦";
          break;
      }
      return x;
    },
    classFormat(status) {
      let x = null;
      switch (status) {
        case 2:
          x = "";
          break;
        case 3:
          x = "white";
          break;
        case 4:
          x = "grey";
          break;
        case 1:
          x = "grey";
          break;
        case 99:
          x = "grey";
          break;
        case 5:
          x = "yellow";
          break;
        case 6:
          x = "orange";
          break;
      }
      return x;
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 14px 68px 14px;
  background: #f9f9f9;
  .list {
    .item {
      width: 100%;
      height: 686px;
      position: relative;
      padding: 12px 17px 20px;
      box-sizing: border-box;
      .item-bottom {
        position: relative;
        height: calc(100% - 426px);
        padding: 38px 25px 0 40px;
        box-sizing: border-box;
        .lastLine {
          display: flex;
          justify-content: space-between;
          height: 62px;
          align-items: center;
          padding-top: 12px;
          .grey {
            background: #e7e7e7 !important;
            color: rgba(0, 0, 0, 0.25) !important;
          }
          .orange {
            background: #fa6400 !important;
            color: #ffffff !important;
          }
          .yellow {
            background-color: transparent !important;
            color: #fa6400 !important;
            border: 2px solid #fa6400;
          }
          .white {
            background-color: transparent !important;
            color: #e2453a !important;
            border: 2px solid #e2453a;
          }
          .lastLine-right {
            box-sizing: border-box;
            width: 206px;
            height: 62px;
            background: #e2453a;
            border-radius: 8px;
            text-align: center;
            line-height: 62px;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .lastLine-left {
            img {
              width: 24px;
            }
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a8a8a8;
          }
        }
        .starTime,
        .drawPrizeTime {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .point {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 14px;
          }
        }
      }
      .item-top {
        position: relative;
        height: 424px;
        .name {
          padding: 0 40px;
          align-items: center;
          height: 94px;
          font-size: 32px;
          line-height: 94px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          overflow: hidden;
        }
        .banner {
          width: 100%;
          height: 330px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>